import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';

import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import type { ReduxState } from '../../store';
import { flashMessageError, flashMessageSuccess } from '../../store/session';
import { setUserProfile } from '../../store/user';
import { FormGroupField } from '../Form/FormGroup';
import FormikSubmitButton from '../Form/SubmitButton';
import T, { useTranslation } from '../Translate';

const ABOUT_ME_MIN_CHARS = 50;
const ABOUT_ME_MAX_CHARS = 300;

interface FormValues {
  aboutMe: string;
}

export default function SetUserDescription() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigate, generatePath } = useRouteContext();
  const user = useSelector((state: ReduxState) => state.user);

  const formik = useFormik<FormValues>({
    validationSchema: object({
      aboutMe: string()
        .required('profile.general.aboutMe.required')
        .min(ABOUT_ME_MIN_CHARS, 'profile.general.aboutMe.min')
        .max(ABOUT_ME_MAX_CHARS, t('profile.general.aboutMe.max', { count: ABOUT_ME_MAX_CHARS })),
    }),
    initialValues: {
      aboutMe: user.data?.about_me ?? '',
    },
    validateOnChange: true,
    async onSubmit(values) {
      try {
        await dispatch(setUserProfile({ about_me: values.aboutMe }));
        dispatch(flashMessageSuccess());
        navigate(generatePath(RouteEnum.MY_PROFILE));
      } catch (error) {
        dispatch(flashMessageError(error.message));
      }
    },
  });

  return (
    <main className="main-container container container--top container--bottom">
      <div className="container container--md">
        <div className="text-center">
          <h1 className="display-1 mb-5">
            <T id="global.basicInfo" />
          </h1>

          <div className="text-muted font-size-lg mt-4">
            <p>
              <T html id="profile.general.basicInfo.text" />
            </p>
            <p className="font-size-sm">
              <T html id="profile.general.basicInfo.note" />
            </p>
          </div>
        </div>

        <FormikProvider value={formik}>
          <Form>
            <FormGroupField
              as="textarea"
              className="mb-5"
              label="profile.general.aboutMe"
              name="aboutMe"
              placeholder="profile.general.aboutMe"
            />

            <div className="text-center">
              <FormikSubmitButton className="btn-lg px-5">
                <T id="global.save" />
              </FormikSubmitButton>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </main>
  );
}
