import React from 'react';

import PageLayout from '../../views/Layout/Page';
import SetUserDescription from '../../views/User/SetUserDescription';

export default function ProfileGeneralPage() {
  return (
    <PageLayout pageview="my_profile_description" private>
      <SetUserDescription />
    </PageLayout>
  );
}
